import { React, useState, useEffect } from "react";
import { render } from "react-dom";
import Swal from "sweetalert2";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import requests from "../../../utils/Requests";
import { Button, Label } from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Titlebanner from "../../../globel_cmponents/title_banner";
import { AiTwotoneEdit, AiFillDelete } from "react-icons/ai";
import { propTypes } from "react-bootstrap/esm/Image";

import Select from "react-select";
const Markuplisttable = () => {
  const [rowData, setRowData] = useState([]);
  const [fullDataList, setfullDataList] = useState([]);
  let history = useHistory();

  useEffect(() => {
    getagency();
  }, []);

  let attraction = {
    attractionsId: 1,
    secretKey: requests.apiKey,
    platformId: 1,
  };
  const [agencyList, setagencyList] = useState([]);

  const getagency = () => {
    axios
      .post(requests.getagencylist, attraction)
      .then((res) => {
        let values = [...agencyList];
        values.length = 0;
        values.push({
          label: "Select Agent",
          value: 0,
        });
        for (let i = 0; i < res.data.length; i++) {
          values.push({
            label: res.data[i].agencyName,
            value: res.data[i].agencyId,
          });
        }
        setagencyList(values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onGridReady = (params) => {
    //  console.log("grid is ready");

    axios
      .post(requests.getmarkupdiscountlist, {
        markupDiscountId: 1,
        platformId: 1,
      })
      .then((res) => {
        const filterData = res.data?.map((item) => ({
          markupDiscountId: item.markupDiscountId,
          agencyName: item.agencyName,
          attractionName: item.attractionName,
          ticketTypeName: item.ticketTypeName,
          markupAdultDiscountPercentage: item.markupAdultDiscountPercentage,
          markupChildDiscountPercentage: item.markupChildDiscountPercentage,
        }));
        setRowData(filterData);
        setfullDataList(filterData);

        console.log(filterData);
        // params.api.applyTransaction({ add: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setagentId = (label) => {
    const filterList = fullDataList?.filter(
      (item) => item.agencyName === label
    );
    console.log(filterList);
    setRowData(filterList);

    console.log("labellabellabellabel", label);
  };

  const actionButton = (params) => {
    // alert(`${params.data.markupDiscountId} `);
    history.push(
      `/Payment/markupDiscount/payment-markup-discount-create/${params.data.markupDiscountId}`
    ); //{`tour-pack-details/${alltour.attractionsId}`}"/Attraction/AttractionCreation/attraction-attraction-creation"
  };
  const columnDefs = [
    { headerName: "Attraction ", field: "attractionName" },
    { headerName: "Ticket Type", field: "ticketTypeName" },

    { headerName: "Agency Name", field: "agencyName" },
    { headerName: "Adult (%)", field: "markupAdultDiscountPercentage" },
    { headerName: "Child (%) ", field: "markupChildDiscountPercentage" },

    {
      headerName: "Action",
      filter: false,
      field: "markupDiscountId",
      cellRendererFramework: (params) => (
        <div>
          <Button color="primary" onClick={() => actionButton(params)}>
            <AiTwotoneEdit />
          </Button>
        </div>
      ),
    },
    {
      headerName: "Delete",
      filter: false,
      field: "markupDiscountId",
      cellRendererFramework: (params) => (
        <div>
          <Button color="danger" onClick={() => deleteMarkup(params)}>
            <AiFillDelete />
          </Button>
        </div>
      ),
    },
  ];

  const deleteMarkup = (params) => {
    //   console.log(params.data.bookingId);

    Swal.fire({
      title: "Warning",
      text: "Are you sure to remove this markup?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios
          .post(requests.deleteMarkupDiscount, {
            markupDiscountId: params.data.markupDiscountId,
          })
          .then((response) => {
            console.log("res.data", response.data);
            if (response.data.errorCode === 200) {
              Swal.fire({
                title: "Success",
                text: "Markup was removed",
                icon: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <Label for="exampleSelect">Filter By Agent</Label>

      <Select
        // value={selectedOption}
        onChange={(e) => setagentId(e.label)}
        options={agencyList}
      />
      <br />
      {rowData.length > 0 && (
        <Button
          color="primary"
          onClick={(e) => exportToCSV(rowData, "Markup_Data")}
        >
          Export Excel
        </Button>
      )}

      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            paginationAutoPageSize={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
};

export default Markuplisttable;
